
//Mobile Menu
.navigationBar__hamburgerIcon {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 24px;
    height: 30px;
}

.navigationBar__hamburgerIcon>span{
    position: relative;
    height: 2px;
    width: 100%;
    background-color: black;
    transition: all 0.3 ease;
}
//open drawer
.openDrawerSpan1{
    animation: openDrawerSpan1 0.5s ease forwards;
}

.openDrawerSpan2{
    animation: openDrawerSpan2 0.5s ease forwards;
}

.openDrawerSpan3{
    animation: openDrawerSpan3 0.5s ease forwards;
}

@keyframes openDrawerSpan1 {
    0% {   
        transform: rotate(0);
        right: 0;
        top: 0;
    }
    100% {   
        transform: rotate(45deg);
        right: 7.8px;
        top: 20px;
    }
}

@keyframes openDrawerSpan2 {
    0% {   
        transform: rotate(0);
        top: 0
    }
    100% {   
        transform: rotate(90deg);
        top: 1px
    }
}

@keyframes openDrawerSpan3 {
    0% {   
        transform: rotate(0);
        left: 0;
        bottom: 0;
    }
    100% {   
        transform: rotate(-45deg);
        left: 7.8px;
        bottom: 8px;
    }
}

//close drawer
.closeDrawerSpan1{
    animation: closeDrawerSpan1 0.5s ease forwards;
}

.closeDrawerSpan2{
    animation: closeDrawerSpan2 0.5s ease forwards;
}

.closeDrawerSpan3{
    animation: closeDrawerSpan3 0.5s ease forwards;
}

//close hamburger icon
@keyframes closeDrawerSpan1 {
    0% {   
        transform: rotate(45deg);
        right: 7.8px;
        top: 20px;
    }
    100% {   
        transform: rotate(0);
        right: 0;
        top: 0;
    }
}

@keyframes closeDrawerSpan2 {
    0% {   
        transform: rotate(90deg);
        top: 1px
    }
    100% {   
        transform: rotate(0);
        top: 0
    }
}

@keyframes closeDrawerSpan3 {
    0% {   
        transform: rotate(-45deg);
        left: 7.8px;
        bottom: 8px;
    }
    100% {   
        transform: rotate(0);
        left: 0;
        bottom: 0;
    }
}