#Contact {
    min-height: 55rem;
    padding-top: 7rem;
}

.contact__text {
    font-size: 1.75rem;
    text-align: center;
}

.contact__section {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 600;
}

.contact__container {
    margin: 7rem auto;
    display: flex;
    width: fit-content;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    flex-wrap: wrap;
    border: 1px solid;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.contact__media {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 600;
    transition: all 300ms ease;
    svg {
        font-size: 25px;
    }
}

.contact__media:nth-child(1):hover{
    color: rgb(209, 21, 21);
}

.contact__media:nth-child(2):hover{
    color: rgb(6, 26, 174);
}

@media (max-width: 780px){
    .contact__container{
        width: 80%;
    }
}
