.downArrow {
    padding-top: 10px;
    display: flex;
    justify-content: right;
    width: 65rem;
    margin: auto;
}

.downArrow>a {
    display: block;
    width: 5rem;
}

.downArrowWing:nth-child(1) {
    height: 13px;
    display: flex;
    span{
        border-bottom: 5px solid;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        width: 24px;
        height: 0;
    }
    .leftWing {
        transform: rotate(45deg);
        position: relative;
        left: 5px;
    }
    .rightWing {
        transform: rotate(-45deg);
        position: relative;
        right: 5.8px;
    }
}

.downArrowWing:nth-child(2) {
    height: 15px;
    display: flex;
    span{
        border-bottom: 5px solid;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        width: 30px;
        height: 0;
    }
    .leftWing {
        transform: rotate(45deg);
    }
    .rightWing {
        transform: rotate(-45deg);
        position: relative;
        right: 12.4px;
    }
}

@media (max-width: 960px) {
    .downArrow {
        width: 100%; 
    }
}