//Nav Menu
nav{
    width: 100%;
    position: fixed;
    z-index: 1;
    //box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.174);
}

.NavigationBar {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 7rem;
    background-color: white;
}

.navigationBar__logo {
    font-size: 2.3rem;
}

.navigationBar__logo:hover {
    cursor: default;
}

.navigationBar__links {
    display: flex;
    gap: 2rem;
    list-style: none;
    font-size: 1.8rem;
}

.navigationBar__links>*>a {
    color: black;
    text-decoration: none;
    text-decoration-color: white;
    transition: all 300ms ease;
}

.navigationBar__links>*>a:hover {
    color: gray;
    text-decoration: underline;
    text-underline-offset: 1rem;
    text-decoration-color: rgb(181,181,181);
}

@media (max-width: 780px) {
    .navigationBar__hamburgerIcon {
        display: flex;
    }
    .navigationBar__links {
        display: none;
    }
    .navigationBar__hamburgerMenu{
        display: block;
    }
    nav {
        box-shadow: none;
    }
}