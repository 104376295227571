#Profile {
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7rem;
}

.profile__container {
  height: fit-content;
  display: flex;
  gap: 5rem;
  justify-content: center;
}
//Picture 
.profile__pictureContainer {
  height: 500px;
  width: 500px;
  margin: auto 0;
}

.profile__pictureContainer>img {
  border-radius: 100%;
}

//Description
.profile__description{
  margin: auto 0;
  align-self: center;
  text-align: center;
}

.profile__description>p {
  font-weight: 600;
}

.profile__text {
  font-size: 1.75rem;
  text-align: center;
}

.profile__name {
  font-size: 3rem;
  font-weight: 600;
}

.profile__job {
  font-size: 2rem;
}
//Info
.profile__info {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.profile__info>button {
  transition: all 300ms ease;
  padding: 1rem;
  border-radius: 2rem;
  font-weight: 600;
  width: 11rem;
}

.profile__social {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.profile__social>a>svg {
  cursor: pointer;
  height: 2rem;
  transition: all 300ms ease;
}

.profile__social>a>svg:hover {
  color:  rgb(53,53,53);
}

@media (max-width: 780px) {
  #Profile {
    height: fit-content;
  }
  .profile__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profile__pictureContainer {
    height: 200px;
    width: 200px;
  }
}
