.navigationBar__hamburgerMenu {
    display: none;
    position: absolute;
    top: -2rem;
    width: 100%;
    background-color: white;
    //box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.174);
    z-index: -1;
    text-align: center;
}

.openDrawer {
    animation: openDrawAnimation 0.5s ease forwards;
}

.closeDrawer{
    animation: closeDrawAnimation 0.5s ease forwards;
}

@keyframes openDrawAnimation {
    0%      { top: -2rem;   }
    80%     { top: 7rem;    }
    90%    { top: 6.85rem;    }
    100%     { top: 7rem;    }
}

@keyframes closeDrawAnimation {
    0%      { top: 6.85rem;   }
    80%     { top: -2rem;    }
    90%     { top: -1.9rem;    }
    100%    { top: -2rem;    }
}

.navigationBar__hamburgerLinks>*>a {
    color: black;
    text-decoration: none;
    text-decoration-color: white;
    transition: all 300ms ease;
    font-size: 1.5rem;
}

.navigationBar__hamburgerLinks>*>a:hover {
    color: gray;
    text-decoration: underline;
    text-decoration-color: rgb(181,181,181);
}
