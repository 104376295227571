.buttonTypeOne {
    color: black;
    border: 0.1rem solid rgb(53,53,53);
}

.buttonTypeTwo {
    background-color: rgb(53,53,53);
    color: white;
}

.buttonTypeOne:hover,
.buttonTypeTwo:hover {
    color: white;
    background-color: black;
}