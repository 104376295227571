#Skill {
    min-height: 55rem;
    padding-top: 7rem;
}

.skill__text {
    font-size: 1.75rem;
    text-align: center;
}

.skill__section {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 600;
}

.skill__container {
    display: flex;
    justify-content: center;
    margin: 5rem auto;
    gap: 3rem;
    flex-wrap: wrap;
}

.skill__filed {
    padding: 1rem 1rem;
    width: 38rem;
    border: 1px solid;
    border-radius: 2rem;
}

.skill__filedTitle {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
}

.skill__experiences {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    article {
        display: flex;
        width: 48%;
        gap:0.6rem;
        justify-content: center;
        margin-bottom: 20px;
        h3{
            font-size: 20px;
            font-weight: 600;
        }
        p{
            font-size: 20px;
            font-weight: 300;
        }
    }
}

.skill__icon {
    font-size: 2rem;
}

@media (max-width: 780px) {
    .skill__container{
      width: 90%;
    }
    .skill__icon{
        font-size: 1.5rem;
    }
    .skill__experiences{
        gap: 0 0.5rem;
    }
    
}