#About {
    min-height: 55rem;
    padding-top: 7rem;
}

.about__container{
    display: flex;
    justify-content: center;
    gap: 2rem;
    width: 100%;
} 

.about__text {
    font-size: 1.75rem;
    text-align: center;
}

.about__section {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 600;
}

.about__pictureContainer {
    width: 25rem;
    img {  
        margin: auto 0;
        border-radius: 2rem;
        background-color: black;
    }
}

.about__detailContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40rem;
    gap: 2rem;
}

.about__detailContainers {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    gap: 2rem;
    .about__details {
        border: 0.1rem solid;
        border-radius: 2rem;
        padding: 2rem 0;
        box-sizing: border-box;
        width: 19rem;
        svg{
            font-size: 2rem;
        }
    }
    h3{
        font-weight: 600;
    }
}

.about__description {
    width: 90%;
    padding: auto;
}



@media (max-width: 960px) {
    #About {
      height: fit-content;
    }
    .about__container {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
    .about__pictureContainer {
      margin-top: 30px;
      height: 300px;
      width: 300px;
    }
    .about__detailContainer{
        width: 100%;
    }
    .about__detailContainers {

    }
}